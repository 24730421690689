$(document).ready(function () {

    if( $('.text-collapse-block').length ) {
        $('.text-collapse-block').each(function() {
           var height = $(this).find('.read-more-content__inner').outerHeight(),
               button = $(this).find('.read-more-content__button'),
               collapseContainer = $(this).find('.read-more-content__collapsed');
            collapseContainer.css('max-height', height + 'px');

           button.on( "click", function() {
               collapseContainer.toggleClass('collapsed');

               if( $(this).hasClass('collapsed') ) {
                   $(this).text('lees minder');
               } else {
                   $(this).text('lees meer');
               }

               $(this).toggleClass('collapsed');
           } );
        });
    }

});
