import '../styles/index.scss';
import './slick-sliders.js';
import './console-log-advert.js';
import './collapse-text.js';
import './newFilter.js';
import './collapse-accordion.js';

require('jquery-simplyscroll');

$(document).mouseup(function(e){
    var soort = $("#soort");

    if(!soort.is(e.target) && soort.has(e.target).length === 0){
        $('#soort').removeClass('open');
    }

    var branche = $("#branche");

    if(!branche.is(e.target) && branche.has(e.target).length === 0){
        $('#branche').removeClass('open');
    }

    var categorie = $("#categorie");

    if(!categorie.is(e.target) && categorie.has(e.target).length === 0){
        $('#categorie').removeClass('open');
    }
});

$(window).resize(function() {
    if ($(window).width() > 768) {
        $('header').removeClass('active');
    }
}).resize();

//SCROLL TO TOP
$(document).on('scroll ready', function () {
    if($(window).width() > 768){
       $('.js-first').toggleClass("scrolled", ($(window).scrollTop() > (($(window).height()) - $('header').height())));
       $('.js-header_top').toggleClass("hide", ($(window).scrollTop() > (($(window).height()) - $('header').height())));
    }
});

var prevTop = 0;


$(document).on('scroll ready', function(evt) {
    var currentTop = $(this).scrollTop();
    if(prevTop !== currentTop) {
        if ($(window).width() > 768) {
            if (prevTop > currentTop) {
                //upward scroll
                $('.js-header_top_second').removeClass("hide");
                $('.js-second').removeClass("scrolled");

            } else if (prevTop < currentTop) {
                //downward scroll
                if ($(window).scrollTop() > ($('header').height())) {
                    $('.js-header_top_second').addClass("hide");
                }
                if ($(window).scrollTop() > ($('header').height())) {
                    $('.js-second').addClass("scrolled");
                }
            }
        }
        prevTop = currentTop;
    }
});

$('.js-qlic-stories').on('click', function(){
    $(this).find('.qlic-stories__story').toggleClass('active');
});

// scroll-padding / scroll-padding
$(document).on('click', 'a[href^="#"]', function (event) {
    event.preventDefault();
    var scroll = true;

    var scrollPlusHeader = 100;
    if($(this).hasClass('accordion-title')) {
        scrollPlusHeader = 125;
        scroll = false;
    }

    if(scroll) {
        $('html, body').animate({
            scrollTop: $($.attr(this, 'href')).offset().top - scrollPlusHeader
        }, 600);
    }
});

$('header .mobile .trigger').on('click', function(){
	$('header').toggleClass('active');
});
$('<i class="fas fa-chevron-down submenu-trigger"></i>').appendTo('#menu-hoofdmenu .menu-item-has-children');
// $('#menu-hoofdmenu .menu-item-has-children').on('click', function(){
$('.submenu-trigger').on('click', function(){
	$(this).closest('li').toggleClass('openmenu');
});

// footer toggle menus on mobile
$('footer .item > .item__header').on('click', function(){
  $(this).find('span').toggleClass('opened');
	$(this).next().closest('div').toggleClass('opened');
});
//core values
$(' .js-core-nav').hover(function(){
    $('.core-values__nav').find($('.open')).removeClass('open');
    $('.core-values__thumb').find($('.open')).removeClass('open');
    $(this).toggleClass('open');
    if ($(window).width() < 991) {
        $(this).next().closest('div').toggleClass('open');
    }
    var id = this.id;
    let content_elements = $('.js-core-content');

    for(var i=0; i < content_elements.length; i++) {
        // var content_id = content_elements[i].id
        if(this.id == $('.js-core-content')[i].getAttribute('data-value')){

            var content_id = $('.js-core-content')[i].getAttribute('data-value');

            if (!$('.js-core-id-' + content_id).hasClass('open')){
                $('.thumb__content').find($('.open')).removeClass('open');
                // $('.js-core-content, #' + content_id).toggleClass('open');
                $('.js-core-id-' + content_id).toggleClass('open');
            }
        }
    }
});
$(document).ready(function () {
    $('.js-core-nav:first-child').toggleClass('open');
    $('.js-core-content:first-child').toggleClass('open');
    if ($(window).width() < 991) {
        $(' .nav__text:first').addClass('open');
    }
});


//why how what module
$('.big__dot, .line, .small__dot, #what__circle, #how__circle, #why__circle').on('click mouseover', function(e){
    if (e.target !== this)
        return;

    var target = $(this).data('target');

    $('.big__circle').find($('.circle')).removeClass('active');

    if($('.big__circle').find('#' + target + '__circle').hasClass('active') === true){
        $('.big__circle').find('#' + target + '__circle').removeClass('active');
    }
    else{
        $('.big__circle').find('#' + target + '__circle').addClass('active');
    }

    $('body').find($('.line__holder')).removeClass('active');
    $('.big__circle').find('#' + target + '__holder').addClass('active');

    $('.circle__items').find($('.circle__item')).removeClass('active');
    $('.circle__items').find('#' + target).addClass('active');

});


$(document).ready(function () {
    $('.big__circle').find('#why__holder').addClass('active');
    $('.circle__items').find('#why').addClass('active');
});


$('.cookie__trigger').on('click', function(){
    $('.cookie__holder').fadeOut(150);
});

//Check if visitor already got pop up modal if not activate modal after delay
$(document).ready(function () {
    $(window).on('load', function() {

        function getCookie(cookiename) {
            var name = cookiename + "=";
            var decodedCookie = decodeURIComponent(document.cookie);
            var ca = decodedCookie.split(';');
            for(var i = 0; i <ca.length; i++) {
                var c = ca[i];
                while (c.charAt(0) == ' ') {
                    c = c.substring(1);
                }
                if (c.indexOf(name) == 0) {
                    return c.substring(name.length, c.length);
                }
            }
            return "";
        }

        if (!getCookie('cookie-notice')){
            setTimeout(function () {


                $('.cookie__trigger').on('click', function(){
                    $('.cookie__holder').toggleClass('open');
                    function setCookie(cookiename, cookievalue, exdays) {
                        var d = new Date();
                        d.setTime(d.getTime() + (exdays*24*60*60*1000));
                        var expires = "expires="+ d.toUTCString();
                        document.cookie = cookiename + "=" + cookievalue + ";" + expires + ";path=/";
                    }
                    setCookie('cookie-notice', true ,7);
                });
            },2000);
        }

    });
});


$(document).ready(function() {
    let pizza__winner = document.getElementsByClassName("item");
    pizza__winner.className += " first__place";
});

//service block homepage
$(' .service__content').hover(function(){
    $( ".service__list .service__inner .service__content"  ).find($('.active')).removeClass('active');
    $( ".service__list .service__inner"  ).find($('.active')).removeClass('active');
    $(this).find($('.service__item')).toggleClass('active');
    var id = this.id;
    let content_elements = $('.js-service-thumb');
    for(var i=0; i < content_elements.length; i++) {
        if(this.id == $('.js-service-thumb')[i].getAttribute('data-value')){
            var content_id = $('.js-service-thumb')[i].getAttribute('data-value');
            if (!$('.js-service-id-' + content_id).hasClass('active')){
                $('.js-service-thumb').find($('.active')).removeClass('active');
                $('.js-service-id-' + content_id).toggleClass('active');
            }
        }
    }
});
$(document).ready(function () {
    $( ".service__list .service__inner .service__content:first-child .service__item"  ).addClass("active");
    $( ".service__list .service__inner .js-service-thumb:first-child"  ).addClass("active");
});

//scroll function smart solutions
$(document).ready(function () {
    $(document).on("scroll", onScroll);
});

function onScroll(event){
    let scrollPos = $(document).scrollTop();
    $('.products__nav_wrapper nav a, .small-wrapper nav a, .products__nav_wrapper-solutions nav a').each(function () {
        let currLink = $(this);
        let refElement = $(currLink.attr("href"));
        let elementPosition = refElement.position().top + $('.taxonomy').height() -2;
        if (elementPosition <= scrollPos && elementPosition + refElement.height() > scrollPos) {
            currLink.addClass("active");
        }
        else{
            if(scrollPos < $('.taxonomy').height()){
                $('.products__nav_wrapper nav a:first-child, .small-wrapper nav a:first-child').addClass("active");
                $('.products__nav_wrapper-solutions nav a:first-child').addClass("active");
            }else{
                currLink.removeClass("active");
            }
        }
    });
}

// Second header/navigation for slimme oplossingen page
let navbar = document.getElementById("js-navbar");
let navbar_second = document.getElementById("js-navbar-second");

if(navbar_second != null){
    window.onload = function() {secondNavbar()};
    window.onscroll = function() {
        secondNavbar();
        scrollsnap();
    };
    var sticky = navbar_second.offsetTop;
    if ($(window).width() > 991) {
        navbar.classList.add("absolute")
    }
}else{
    sticky = null;
}

// second navbar for smart solutions
function secondNavbar() {
    if ($(window).width() > 991) {
        if (window.pageYOffset >= sticky) {
            navbar_second.classList.add("sticky")
        } else {
            navbar_second.classList.remove("sticky");
        }
    }
}

// scroll snap for smart solutions
console.log($('.smart__items').height());
function scrollsnap() {

    // console.log("huidige scroll:" + window.pageYOffset);
    let smart_items_bottom = $('.smart__items').height() + 400;
    let taxonomy_bottom = $('.taxonomy').height();
    // console.log('bovenkant taxonomy' + taxonomy_bottom);
    // console.log('onderkant taxonomy' + smart_items_bottom);
    if ($(window).width() > 991) {
        if (window.pageYOffset > taxonomy_bottom && window.pageYOffset < smart_items_bottom) {
            $("html").attr("style", "scroll-snap-type:y mandatory");

        } else {
            $("html").removeAttr("style", "scroll-snap-type:y mandatory");
        }
    }
}

$(function() {
    var path = window.location.href;
    $('.filterbtn').each(function() {
     if (this.href === path) {
      $(this).addClass('active');
        }
    });
});


//Parallax for promises-large blok for scrolling
$(window).scroll(function () {

    $('.promise-block-bg').css({
        'top' : -($(this).scrollTop()/10 - 90)+"px"
    });
});

//Parallax for promises-large blok when page is loaded
$(window).on('load', function() {
    $('.promise-block-bg').css({
        'top' : -($(this).scrollTop()/10 - 90)+"px"
    });
});


// player for taxonomy (why block)
import Plyr from 'plyr';
const player = new Plyr('#player', {});
// Expose player so it can be used from the console
window.player = player;



